<template>
  <div class="accounting-assignment-positions pt-4 pb-8 w-full">
    <vs-row vs-w="12" class="mb-4">
      <vs-col vs-offset="6" vs-type="flex" vs-justify="right" vs-align="right" vs-w="6">
        <vs-switch color="success" v-model="displayShortDescriptions" name="displayShortDescriptions" class="mr-2"/>    <label for="displayShortDescriptions"><b>Ausführliche Darstellung</b></label>
      </vs-col>
    </vs-row>

    <vue-bootstrap4-table
        v-if="accountings"
        :rows="accountings"
        :columns="columns"
        :config="config"
        @on-select-row="onSelectRows"
        @on-unselect-row="onSelectRows"
        class="mt-4 pb-4 border-bottom-light">
      <template slot="empty-results">
        Keine abrechnungspositionen gefunden.
      </template>


      <template slot="order_id" slot-scope="props">
        <div v-if="props.row.order_id == null">
          <span class="mega--circle bg-gray-300 "></span>
        </div>
        <div v-if="props.row.order_id && props.row.order">
          <div v-if="props.row.order.invoice_number">
            <vx-tooltip :text="'Rechnung ' + props.row.order.invoice_number + ' / ' + formatDate(props.row.order.order_date)">
              <span class="mega--circle green"></span>
            </vx-tooltip>
          </div>

          <div v-if="!props.row.order.invoice_number">
            <vx-tooltip :text="'Rechnungsentwurf'">
              <span class="mega--circle yellow"></span>
            </vx-tooltip>
          </div>
        </div>



        <div></div>
      </template>

      <template slot="amount" slot-scope="props">
        <div class="text-center">
          {{ props.row.amount}} {{getVolumeUnitNameById(props.row.volume_unit)}}
        </div>
      </template>

      <template slot="row_actions" slot-scope="props">
        <div class="column-actions-group" >

          <router-link  v-if="!props.row.order_id"  :to="'/accounting/'+props.row.id" >
            <vx-tooltip text="Bearbeiten">
              <vs-button color="warning" type="filled" icon="edit" size="small"
                         :to="'/accounting/'+props.row.id" ></vs-button>
            </vx-tooltip>
          </router-link>

          <router-link  v-if="props.row.order_id"  :to="'/accounting/'+props.row.id" >
            <vx-tooltip text="Ansehen" >
              <vs-button color="dark" type="filled" icon="visibility" size="small"
                         :to="'/accounting/'+props.row.id"  ></vs-button>
            </vx-tooltip>
          </router-link>
        </div>
      </template>

      <template slot="article_name" slot-scope="props">
        <div class="mt-2">  {{ props.row.article_name }}</div>

        <small class="short_description" v-if="props.row.short_description && displayShortDescriptions" v-html="props.row.short_description">
          {{ props.row.short_description }}
        </small>
        <div class="column-actions-group flex" v-if="props.row.accounting_start != null && props.row.accounting_end !=null && props.row.contract_start !=null && props.row.contract_end !=null">

          <vx-tooltip  position="bottom">
            <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
            <template v-slot:text>
              Abrechnungszeitraum: <br>{{formatDate(props.row.accounting_start) }} - {{ formatDate(props.row.accounting_end)}}<br>
              Vertragslaufzeit {{formatDate(props.row.contract_start)}} - {{formatDate(props.row.contract_end)}}
            </template>
          </vx-tooltip>

          <vx-tooltip text="Zum Vertrag >"  position="top">
            <div v-if="props.row.contract_id != null">
              <vs-button  type="flat"
                          color="warning"
                          icon="description"
                          size="small"
                          target="_blank"
                          class="padding-0"
                          :to="'/contracts/'+props.row.contract_id">
              </vs-button>
            </div>

          </vx-tooltip>
        </div>
      </template>

      <template slot="total_price" slot-scope="props">
        <div class="text-center">
          {{ props.row.unit_price * props.row.amount}} €
        </div>
      </template>
      <template slot="unit_price" slot-scope="props">
        <div class="text-center" >
          {{ props.row.unit_price }} €
        </div>
      </template>

      <template slot="article" slot-scope="props">
        <div class="text-center" v-if="props.row.article.ordernumber">
          {{ props.row.article.name}} <br><small>(Nr.:{{ props.row.article.ordernumber}})</small>
        </div>
      </template>

      <template slot="created_at" slot-scope="props">
        {{formatDate(props.row.created_at)}}
        <small v-if="props.row.creator"> <br>von  {{ props.row.creator.detail.first_name}} {{ props.row.creator.detail.last_name}}</small>

      </template>

      <template slot="invoice_number" slot-scope="props">
        <div v-if="props.row.order_id == null">
          -
        </div>
        <div v-if="props.row.order_id != null">
            <span v-if="props.row.order.invoice_number == null">
              Rechnungsentwurf
            </span>
          <span v-if="props.row.order.invoice_number ">
              <vx-tooltip :text="'Rechnung ' + props.row.order.invoice_number + ' / ' + formatDate(props.row.order.order_date)">
                 <router-link  v-if="props.row.order.invoice_number != null"  :to="'/invoices/view/'+props.row.order_id" >{{props.row.order.invoice_number}}</router-link>
              </vx-tooltip>
            </span>
        </div>
      </template>

    </vue-bootstrap4-table>

    <vs-button color="success" class="mt-5"  @click="openOfferAllocationPopup" :disabled="selectedData.length == 0">Markierte Abrechnungspositionen mit diesem Angebot verknüpfen </vs-button>


    <vs-popup  title="Angebot zuweisen"   :active.sync="isOfferAllocationPopupOpen">

      <vs-alert color="#ff0000" :active="offers.length == 0" icon="error" class="mb-4 small-alert" >
        Keine Angebote für den Kunden gefunden, welches den Status "akzeptiert" oder "im Projekt" hat.
      </vs-alert>

      <vs-row>
        <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-8">
          <mg-select v-model="offerAllocationSelectedOfferId"  :options="offers" v-validate="'required'" data-vv-as="Angebot"   placeholder="Angebot auswählen" track-by="id"  label="id" @select="onOfferChange"  :custom-label="customOfferLabel">

            <template slot="singleLabel" slot-scope="{ option }">
              <strong>Angebot Nr. {{ option.invoice_number }} </strong><br><small>{{option.title}}</small>
            </template>

            <template slot="option" slot-scope="props">
                                    <span>
                                        {{props.option.invoice_number}} <small>({{props.option.title}} - {{formatDate(props.option.order_date)}})</small></span>
            </template>

          </mg-select>
        </vs-col>

        <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-8" v-if="offerAllocationSelectedOfferId">
          <mg-select v-model="offerAllocationSelectedOfferItemId"  :options="offerItems" v-validate="'required'" name="offerItem" data-vv-as="Angebotsposition"   placeholder="Optional: Position auswählen" track-by="id"  label="id" :custom-label="customOfferItemLabel">

            <template slot="singleLabel" slot-scope="{ option }">
              <strong>{{ option.product_name }}</strong><br>
              <small>Angebotene Menge: {{option.amount}} {{getVolumeUnitNameById(parseInt(option.volume_unit))}}</small>
            </template>

            <template slot="option" slot-scope="props">
              <span>{{props.option.product_name}} ({{props.option.amount}} {{getVolumeUnitNameById(parseInt(props.option.volume_unit))}}) <br><small v-html="props.option.short_description">{{props.option.short_description}}</small></span>
            </template>

          </mg-select>
        </vs-col>
      </vs-row>

      <vs-row>
        <i class="mb-2">[ i ] Du kannst entweder ein <b>Angebot</b> oder eine <b>Angebotsposition</b> dieser Abrechnung hinzufügen. Wenn möglich immer Angebotspositionen hinterlegen.</i>
        <vs-button @click="selectOffer" :disabled="!offerAllocationSelectedOfferId">Angebot hinterlegen</vs-button>
      </vs-row>

    </vs-popup>




  </div>
</template>

<script>

import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';
import ApiService from "../../../../../api";
import QueryHelper from "../../../../../mixins/helper/query.helper";
import * as qs from "qs";
import PriceHelper from "../../../../../mixins/helper/price.helper";
import moment from "moment";
import staticOptions from "../../../../../mixins/static/options";
import MgSelect from "../../../../../components/mg-select/MgSelect";

export default {
  name: "AssignmentPositions",
  components: {
    VueBootstrap4Table,
    MgSelect
  },
  data() {
    return {
      offer: [],
      dataFetched: true,
      total_rows: 0,
      orderItems: [],
      selectedData:[],
      accountings: [],
      displayShortDescriptions: false,
      columns: [
        {
          label: "Abg.",
          name: "order_id",
          slot_name: "order_id",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          column_classes:"column-header-order"
        },
        {
          label: "Produktname",
          name: "article_name",
          slot_name: "article_name",
          sort: false,
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Artikel",
          name: "article.ordernumber",
          slot_name: "article",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
          {
            label: "Rechnungsnummer",
            name: "order.invoice_number",
            filter: {
              type: "simple",
              placeholder: "Suchen..."
            },
            slot_name: "invoice_number",
            sort: false,
            row_text_alignment: 'text-left',
            column_text_alignment: 'text-left',
          },
        {
          label: "Menge",
          name: "amount",
          slot_name: "amount",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          column_classes:"column-header-amount"
        },
        {
          label: "Einzelpreis",
          name: "unit_price",
          slot_name: "unit_price",

          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Gesamtpreis",
          name: "unit_price",
          slot_name: "total_price",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Erstellt",
          name: "created_at",
          slot_name: "created_at",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: this.formatDateTime,
          initial_sort: true, //
          initial_sort_order: "asc" //
        },

        {
          label: "Actions",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'actions-column',
          showCol: true,
        }
      ],
      config: {
        global_search:  {
          visibility:  false,
        },
        checkbox_rows: true,
        rows_selectable: true,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
      },

      isOfferAllocationPopupOpen: false,
      offers: [],
      offerItems: [],
      offerAllocationSelectedOfferId: null,
      offerAllocationSelectedOfferItemId: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$vs.loading()
      this.fetchOrderData().then((response) => {
        this.fetchAccountingsData();
        this.$vs.loading.close()
      });

    },
    async fetchOrderData() {
      const id = this.$route.params.id;
      return ApiService.get(`orders/${id}`).then(response => {
        this.offer = response.data.result;
      }).catch(response => {
      })
    },
    async fetchAccountingsData() {
      let queryParams = {
        filters: [],
        limit: 10000,
        offset: 0
      };

      let filterParams = QueryHelper.parseRequestParams(queryParams);

      filterParams.filter.push(
          {
            field: 'client_id',
            value: this.offer.client_id,
            expression: 'exact',
          },
          {
            field: 'offer_id',
            value: 'null',
            expression: 'exact',
          },
          {
            field: 'offer_item_id',
            value: 'null',
            expression: 'exact',
          },
      );
      filterParams.includes =  "article.type";

      ApiService.get('accountings', {
        params: filterParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.accountings = response.data.result;
        this.total_rows = response.data.total;
      })
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    formatDateTime(value) {
      return moment(new Date(value)).format('DD.MM.YYYY - H:m')
    },
    getVolumeUnitNameById(id) {
      const units = staticOptions.units.find(item => item.value === id);
      return units.labelShort
    },
    onSelectRows(payload) {
      this.selectedData = []
      this.selectedData = payload.selected_items
    },
    fetchOffers() {
      let queryParams = {
        filter: [
          {
            field: 'type.invoice_type',
            expression: 'exact',
            value: 'offer'
          },
          {
            field: 'client_id',
            expression: 'exact',
            value: this.offer.client_id
          },
          {
            expression: 'group',
            filter: [
              {
                field: 'status',
                expression: 'exact',
                value: '16',
                operator: 'or',
              },
              {
                field: 'status',
                expression: 'exact',
                value: '18',
                operator: 'or',
              },
              {
                field: 'status',
                expression: 'exact',
                value: '19',
                operator: 'or',
              }
            ]
          }
        ]
      };

      if (this.offer.id){
        queryParams = {
          filter: [
            {
              field: 'id',
              expression: 'exact',
              value: this.offer.id
            },
          ]
        };
      }

      ApiService.get('orders', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.offers = response.data.result;

        if (this.offers.length == 1) {
          this.offerAllocationSelectedOfferId = this.offers[0].id
          this.onOfferChange(this.offers[0])
        }
        return true;
      })
    },
    openOfferAllocationPopup(){
      this.fetchOffers();
      this.isOfferAllocationPopupOpen = true;
    },
    customOfferLabel(option) {
      return `${option.invoice_number} - ${option.title} - ${option.order_date}`;
    },
    customOfferItemLabel(option) {
      return `${option.product_name} - ${option.title} - ${option.short_description}`;
    },
    onOfferChange(item){
      this.offerItems = [];
      this.offerItems = item.items;
    },
    selectOffer(){
      this.isOfferAllocationPopupOpen = false;
      this.saveSelectedAccountings(this.selectedData[0]).then((response) => {
          this.isOfferAllocationPopupOpen = false;
      }).catch((response) => {
        this.$vs.notify({
          title: 'Fehler',
          text: "Konnte nicht gespeichert werden",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    saveSelectedAccountings() {
      if (this.selectedData.length > 0) {
        for(let i = 0;i < this.selectedData.length; i++) {
          this.saveAccounting(this.selectedData[i]);
        }
        return this.fetchAccountingsData();
      }
      return false;
    },
    saveAccounting(accounting) {
      let data = {
        offer_item_id: this.offerAllocationSelectedOfferItemId,
        offer_id: this.offerAllocationSelectedOfferId,
      };

      return ApiService.put(`accountings/${accounting.id}`, data).then(() => {
        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Die Änderung wurde erfolgreich gespeichert.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        });
        return true

      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehler',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false;
      });
    }
  }
}
</script>

<style lang="scss">
.accounting-assignment-positions {
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  .short_description {
    color:#bdbdbd;

    li,span {
      color:#bdbdbd!important;
    }
  }
}
</style>
